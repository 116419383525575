/*global module, Logger*/
module.exports = function observable(base) {
  'use strict';
  let listeners = [];
  base.addEventListener = function (types, listener, priority) {
    types.split(' ').forEach(function (type) {
      if (type) {
        listeners.push({
          type: type,
          listener: listener,
          priority: priority || 0
        });
      }
    });
  };
  base.listeners = function (type) {
    return listeners.filter(function (listenerDetails) {
      return listenerDetails.type === type;
    }).map(function (listenerDetails) {
      return listenerDetails.listener;
    });
  };
  base.removeEventListener = function (type, listener) {
    listeners = listeners.filter(function (details) {
      return details.listener !== listener;
    });
  };
  base.dispatchEvent = function (type) {
    const args = Array.prototype.slice.call(arguments, 1);
    listeners
      .filter(function (listenerDetails) {
        return listenerDetails.type === type;
      })
      .sort(function (firstListenerDetails, secondListenerDetails) {
        return secondListenerDetails.priority - firstListenerDetails.priority;
      })
      .some(function (listenerDetails) {
        try {
          return listenerDetails.listener.apply(undefined, args) === false;
        } catch (e) {
          Logger.trace('dispatchEvent failed', e, listenerDetails);
        }

      });
  };
  return base;
};
